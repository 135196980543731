import React, { useEffect } from "react";


// import allData from "./allData/allData";
import allData from "./../../allData";


import css from "./Molasses.module.scss";
// import MainTitle from "../../components/MainTitle/MainTitle";
// import AboutUs from "../../components/AboutUs/AboutUs";
// import Product from "../../components/Product";
// import Description from "../../components/Description";
import Partners from "../../components/Partners/Partners";
import Contacts from "../../components/Contacts";
import Info from "../../components/Info";
// import Footer from "../../components/Footer";
import MainDescription from "../../components/MainDescription/MainDescription";
import { MainSlider } from "../../components/MainSlider/MainSlider";

// import globalData from '../../allData'
import ForWhat from "../../components/ForWhat/ForWhat";
import Details from "../../components/Details/Details";
import Packaging from "../../components/Packaging/Packaging";

import orangeBg from '../../media/orange-bg.svg';
import orangeBgSafari from '../../media/orange-bg-safari.svg';
import { useState } from "react";

const {
  // dataPartners,
  // // dataContacts,
  // dataInfo,
  // slideMolasData,
  // whatMolasData,
  // detailsMolas,
  // dataPackagingMolas,
  // dataMainDescriptionMolasses
} = allData;

// const {
//   dataMainDescriptionMolasses
// } = globalData;

const Molasses = () => {

  const [bg, setBg] = useState('');

  useEffect(()=>{
    const appleExpression = /(Mac|iPod|iPad)/i;
    if (appleExpression.test(navigator.platform)) {
      setBg(orangeBgSafari);
    } else {
      setBg(orangeBg);
    }
  }, [])

  return (
    <>
      {/* <section id="about">
        <MainTitle mainTitle={dataMainTitle} />
      </section> */}
      <section className={css['head-melasa']} style={{backgroundImage: `url(${bg})`}}>
        <MainSlider slideData={'slideMolasData'}/>
      </section> 
      <section>
        <MainDescription data= {'dataMainDescriptionMolasses'}/>
      </section>
      <section>
        <ForWhat data = {'whatMolasData'}/>
      </section>
      <section>
        <Details data = {'detailsMolas'} />
      </section>
      <section>
        <Packaging data={'dataPackagingMolas'} type={'molasses'} />
      </section>
      <section>
        <Partners data={'dataPartners'} />
      </section>
      <section>
        <Contacts data={'dataContacts'} />
      </section>
      <section>
        <Info data={'dataInfo'} />
      </section>
      
    </>
  );
};

export default Molasses;
