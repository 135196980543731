// Layout.jsx
import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import allData from "../allData";
import Container from "../components/Container";

const { dataContacts, dataNavigation } = allData;

const Layout = () => {
  return (
    <>
      <header>
        <Container> 

        <Header data = {dataNavigation.header}/>
        </Container>
      </header>
      <main>
        <Outlet />
      </main>

      <footer id="contacts">
        <Footer data = {dataNavigation.footer} />
      </footer>
    </>
  );
};

export default Layout;
