import React from "react";
import css from "./DetailsCompostMobile.module.scss";
import Container from "../Container";
import { useTranslation } from "react-i18next";

const DetailsCompostMobile = ({ data }) => {

  const { t } = useTranslation();
  const organ = t(`${data}.organ` , { returnObjects: true });
  const him = t(`${data}.him` , { returnObjects: true });

  return (
    <Container>
      <div className={css.wrapper}>
        <div className={css.wrapperList_1}>
          <h3 className={css.title}>{t(`${data}.title1`)} <span className={css.titleDecor}>{t(`${data}.subtitle`)}</span></h3>

          <ul className={css.list}>
            {organ.map((el, i) => (
              <li key={i} className={css.item}>
                <p className={css.textTitle}>{el.title}</p>
                <p className={css.textDescription}>{el.subtitle}</p>
              </li>
            ))}
          </ul>
        </div>

        <div className={css.wrapperList_2}>
        <h3 className={css.title}>{t(`${data}.title2`)} <span className={css.titleDecor}>{t(`${data}.subtitle`)}</span></h3>

          <ul className={css.list}>
            {him.map((el, i) => (
              <li key={i} className={css.item}>
                <p className={css.textTitle}>{el.title}</p>
                <p className={css.textDescription}>{el.subtitle}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default DetailsCompostMobile;
