import React, { useEffect, useState } from "react";
import css from "./ButtonLocation.module.scss";
import Title from "../Title/Title";
import Container from "../Container";
import GoogleMaps from "../GoogleMaps";
import SimpleSlider from "../SimpleSlider/SimpleSlider";

const ButtonLocation = ({ objData, shangState, activeButton }) => {

  return (
    <button
      className={objData.title_1 === activeButton ? css.activeButton : ""}
      onClick={() => shangState(objData.title_1)}
    >
      <p className={css.text_1}>{objData.title_1}</p>
      <p className={css.text_2}>{objData.title_2}</p>
    </button>
  );
};

export default ButtonLocation;
