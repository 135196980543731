// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../media/brown-bg-mobile.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Compost_homeWrapper__JncyV {
  margin: 0 auto 0 auto;
  background-color: tomato;
}
.Compost_head-compost__tTVxg {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 96px;
  opacity: 0.999;
}

.Compost_compost-detail__3pbQ6 {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}

@media screen and (max-width: 1050px) {
  .Compost_head-compost__tTVxg {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Compost/Compost.module.scss"],"names":[],"mappings":"AAAA;EACA,qBAAA;EACA,wBAAA;AACA;AAQA;EACE,+BAAA;EACA,sBAAA;EACA,4BAAA;EACA,mBAAA;EACA,cAAA;AANF;;AASA;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;AANF;;AASA;EACE;IACE,8DAAA;EANF;AACF","sourcesContent":[".homeWrapper {\nmargin: 0 auto 0 auto;\nbackground-color: tomato  ;\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n  }\n}\n\n.head-compost {\n  background-position: top center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  margin-bottom: 96px;\n  opacity: 0.999;\n}\n\n.compost-detail {\n  display: flex;\n  align-items: center;\n  margin-bottom: 100px;\n}\n\n@media screen and (max-width: 1050px) {  \n  .head-compost {\n    background: url('../../media/brown-bg-mobile.svg') !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeWrapper": `Compost_homeWrapper__JncyV`,
	"head-compost": `Compost_head-compost__tTVxg`,
	"compost-detail": `Compost_compost-detail__3pbQ6`
};
export default ___CSS_LOADER_EXPORT___;
