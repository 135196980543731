import React from "react";
import { ReactComponent as LogoSvg } from './../../media/icons/Logo.svg';
import { ReactComponent as LogoSvg2 } from './../../media/icons/Logo_2.svg';  // Змінив назву для уникнення помилок
import css from './Logo.module.scss';

const Logo = ({ color }) => {
  return (
    <div className={css.wrapper}>
      {color === 'green' ? <LogoSvg className={css.icon} /> : <LogoSvg2 className={css.icon} />}
    </div>
  );
};

export default Logo;
