import React, { useEffect, useState } from "react";


import allData from "../../allData/allData";


import { useTranslation } from "react-i18next";

import css from "./Home.module.scss";
import MainTitle from "../../components/MainTitle/MainTitle";
import AboutUs from "../../components/AboutUs/AboutUs";
import Product from "../../components/Product";
import Description from "../../components/Description";
import Partners from "../../components/Partners/Partners";
import Contacts from "../../components/Contacts";
import Info from "../../components/Info";
import Footer from "../../components/Footer";
import UniversalModalWindow from "../../components/UniversalModalWindow";
import FormApplication from "../../components/FormApplication";
import QuestionForm from "../../components/QuestionForm";
import Container from "../../components/Container";


const {
  dataMainTitle,
  dataAboutUs,
  dataProduct,
  dataDescription,
  dataPartners,
  dataContacts,
  dataInfo,
  orderForm

} = allData;

const Home = () => {

  const [modal1Active, setModal1Active] = useState(false);
  const [modal2Active, setModal2Active] = useState(false);



  return (
    <>
      {/* <section>
        <SimpleSlider dataSlider={dataSlider_1} />
      </section> */}
      <section>
        <MainTitle mainTitle={'dataMainTitle'} setModal1Active={setModal1Active} dataForm={orderForm}/>
      </section>
      <section>
        <AboutUs dataAboutUs={dataAboutUs} />
      </section>
      <section>
        <Product dataProduct={dataProduct} />
      </section>
      <section>
        <Description dataDescription={dataDescription} />
      </section>
      <section>
        <Partners data={'dataPartners'} />
      </section>
      <section>
        <Contacts data={'dataContacts'} />
      </section>
      <section>
        <Info data={'dataInfo'} />
      </section>




      {/* <UniversalModalWindow active={modal1Active} setActive={setModal1Active} >
           <QuestionForm setActive2={setModal2Active} setActive1={setModal1Active}/>
      </UniversalModalWindow>



      <UniversalModalWindow active={modal2Active} setActive={setModal2Active}>
          <FormApplication data={orderForm}/>
      </UniversalModalWindow> */}


      
    
      
    </>
  );
};

export default Home;
