import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import css from "./MainTitle.module.scss";
import Container from "../Container";
import FormApplication from "../FormApplication";

const MainTitle = ({ mainTitle, setModal1Active, dataForm }) => {
  const { t } = useTranslation();
  const [openForm, setOpenForm] = useState(false);
  const formRef = useRef(null);

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setOpenForm(false);
    }
  };

  useEffect(() => {
    if (openForm) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openForm]);

  return (
    <div className={css.wrapperImg}>
      <Container>
        <div className={css.wrapper} ref={formRef}>
          {openForm ? (
            <FormApplication closeForm={setOpenForm} orderForm={dataForm} />
          ) : (
            <>
              <div className={css.wrapperText}>
                <h1 className={css.textStyle}>{t(`${mainTitle}.title`)}</h1>
                <button
                  className={`${css.buttonStyle} ${openForm ? css.active : ""}`}
                  onClick={() => setOpenForm(!openForm)}
                >
                  {t(`${mainTitle}.textButton`)}
                </button>
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default MainTitle;
