import React, { useState, useEffect, useRef } from "react";
import css from "./SimpleSlider.module.scss";
import ButtonLocation from "../ButtonLocation";
import { ReactComponent as ArrowLeftIcon } from "../../media/icons/decor/arrow_left.svg";
import { ReactComponent as ArrowRightIcon } from "../../media/icons/decor/arrow_right.svg";
import { useLocation } from "react-router";

const SimpleSlider = ({ dataSlider, shangState, activeButton }) => {
  // console.log("dataSlider", dataSlider);
  const location = useLocation();
  // кількість слайдів
  const totalSlides = dataSlider.length;
  // починаємо з середини відображати
  const initialIndex = Math.floor((totalSlides - 1) / 2);

  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [itemWidth, setItemWidth] = useState(0);
  const listRef = useRef(null);

  // useEffect(() => {
  //   console.log('itemWidth', itemWidth);
  // }, [itemWidth]);

  // функція для зміни індексу слайдера
  const clickArrow = (change) => {
    const nextIndex = currentIndex + change;

    // перевірка, щоб не вийти за межі діапазону слайдів
    if (nextIndex < 0 || nextIndex >= totalSlides) {
      return;
    }

    setCurrentIndex(nextIndex);

    // shangState(dataSlider[nextIndex].title_1)
  };

  useEffect(() => {
    shangState(dataSlider[currentIndex].title_1);
  }, [currentIndex]);

  // ефект для оновлення ширини екрану
  useEffect(() => {
    const updateItemWidth = () => {
      if (listRef.current) {
        const firstItem = listRef.current.children[0];
        setItemWidth(firstItem.offsetWidth);

      }
    };

    updateItemWidth();

    window.addEventListener("resize", updateItemWidth);
    return () => {
      window.removeEventListener("resize", updateItemWidth);
    };
  }, []);

  return (
    <>
      <div className={css.wrapper}>
        <div className={css.containerSlider}>
          <div className={css.wrapperArrow}>
            {/* Кнопка для переходу до попереднього слайду */}
            <button className={css.buttonArrow} onClick={() => clickArrow(-1)}>
              <ArrowLeftIcon className={css.icon} />
            </button>

            <div className={css.wrapperSlider}>
              {/* Список слайдів */}
              <ul
                className={css.list}
                ref={listRef}
                style={{
                  transform: `translateX(-${currentIndex * (itemWidth + 200)}px)`,
                }}

          

                
              >
                {dataSlider.map((slide) => (
                  <li key={slide.id} className={css.item}>

                    <div className={css.wrapperCardLi}>


                    <ul className={css.list_1}>
                      {location.pathname === "/compost"
                        ? slide.packagingCompost.map((element, i) => (
                            <li className={css.item_1} key={i}>
                              <img src={element.imgUrl} alt={element.weight} />
                              <p>{element.weight}</p>
                            </li>
                          ))
                        : slide.packagingMolas.map((element, i) => (
                            <li className={css.item_1} key={i}>
                              <img src={element.imgUrl} alt={element.weight} />
                              <p>{element.weight}</p>
                            </li>
                          ))}
                    </ul>

                    <ButtonLocation
                      objData={slide}
                      activeButton={activeButton}
                      />

                      </div>


                  </li>
                ))}
              </ul>

              {/* Пагінація */}
              <div className={css.pagination}>
                <div className={css.pagination_dots}>
                  {Array.from({ length: totalSlides }).map((_, index) => (
                    <div
                      key={index}
                      className={`${css.dot} ${
                        index === currentIndex ? css.activeDot : ""
                      }`}
                      onClick={() => setCurrentIndex(index)}
                    ></div>
                  ))}
                </div>
              </div>
            </div>

            {/* Кнопка для переходу до наступного слайду */}
            <button className={css.buttonArrow} onClick={() => clickArrow(1)}>
              <ArrowRightIcon className={css.icon} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimpleSlider;
