// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutUs_wrapper__dB6sA {
  margin-bottom: 70px;
}
.AboutUs_wrapper__dB6sA .AboutUs_titleUsWrapper__xoGPi {
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
}
.AboutUs_wrapper__dB6sA .AboutUs_contentWrapper__OsIUM {
  background-color: #5d9624;
  display: flex;
  padding: 32px 50px 32px 50px;
  gap: 30px;
  justify-content: space-between;
  border-radius: 16px;
  align-items: center;
}
.AboutUs_wrapper__dB6sA .AboutUs_contentWrapper__OsIUM .AboutUs_text__tKn5Y {
  width: 50%;
}
.AboutUs_wrapper__dB6sA .AboutUs_contentWrapper__OsIUM .AboutUs_text__tKn5Y .AboutUs_wrapperTitleContent__S4GyR {
  margin-bottom: 30px;
}
.AboutUs_wrapper__dB6sA .AboutUs_contentWrapper__OsIUM .AboutUs_text__tKn5Y .AboutUs_description_1__vRMQM {
  margin-bottom: 30px;
}
.AboutUs_wrapper__dB6sA .AboutUs_contentWrapper__OsIUM .AboutUs_text__tKn5Y .AboutUs_description_1__vRMQM a {
  text-decoration: underline;
}
.AboutUs_wrapper__dB6sA .AboutUs_contentWrapper__OsIUM .AboutUs_text__tKn5Y .AboutUs_description_1__vRMQM,
.AboutUs_wrapper__dB6sA .AboutUs_contentWrapper__OsIUM .AboutUs_text__tKn5Y .AboutUs_description_2__CEcko {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--main-color);
}
@media (max-width: 768px) {
  .AboutUs_wrapper__dB6sA .AboutUs_contentWrapper__OsIUM .AboutUs_text__tKn5Y {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .AboutUs_wrapper__dB6sA .AboutUs_contentWrapper__OsIUM {
    flex-direction: column;
    padding: 16px;
    margin-bottom: 70px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AboutUs/AboutUs.module.scss"],"names":[],"mappings":"AAAA;EACA,mBAAA;AACA;AAEE;EACE,mBAAA;EAEA,eAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAWE;EAEE,yBAAA;EACA,aAAA;EACA,4BAAA;EACA,SAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AAVJ;AAYI;EACE,UAAA;AAVN;AAYM;EACE,mBAAA;AAVR;AAaM;EACE,mBAAA;AAXR;AAaQ;EACE,0BAAA;AAXV;AAeM;;EAEE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,wBAAA;AAbR;AAmBM;EA1BF;IA4BI,WAAA;EAjBN;AACF;AAuBI;EA7CF;IA8CI,sBAAA;IACA,aAAA;IACA,mBAAA;EApBJ;AACF","sourcesContent":[".wrapper {\nmargin-bottom: 70px;\n\n\n  .titleUsWrapper {\n    margin-bottom: 30px;\n\n    font-size: 32px;\n    font-weight: 700;\n    line-height: 32px;\n    // border: 1px solid rgb(77, 46, 179);\n\n    @media (max-width: 1360px) {\n    }\n\n    @media (max-width: 768px) {\n    }\n  }\n\n  .contentWrapper {\n    // border: 1px solid tomato;\n    background-color: #5d9624;\n    display: flex;\n    padding: 32px 50px 32px 50px;\n    gap: 30px;\n    justify-content: space-between;\n    border-radius: 16px;\n    align-items: center;\n\n    .text {\n      width: 50%;\n\n      .wrapperTitleContent {\n        margin-bottom: 30px;\n      }\n\n      .description_1 {\n        margin-bottom: 30px;\n\n        a {\n          text-decoration: underline;\n        }\n      }\n\n      .description_1,\n      .description_2 {\n        font-size: 16px;\n        font-weight: 400;\n        line-height: 24px;\n        color: var(--main-color);\n      }\n\n      @media (max-width: 1360px) {\n      }\n  \n      @media (max-width: 768px) {\n    //  padding-top: 32px;\n        width: 100%;\n      }\n    }\n\n    @media (max-width: 1360px) {\n    }\n\n    @media (max-width: 768px) {\n      flex-direction: column;\n      padding: 16px;\n      margin-bottom: 70px;\n    }\n  }\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AboutUs_wrapper__dB6sA`,
	"titleUsWrapper": `AboutUs_titleUsWrapper__xoGPi`,
	"contentWrapper": `AboutUs_contentWrapper__OsIUM`,
	"text": `AboutUs_text__tKn5Y`,
	"wrapperTitleContent": `AboutUs_wrapperTitleContent__S4GyR`,
	"description_1": `AboutUs_description_1__vRMQM`,
	"description_2": `AboutUs_description_2__CEcko`
};
export default ___CSS_LOADER_EXPORT___;
