// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../media/orange-bg-mobile.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Molasses_homeWrapper__qxRUU {
  margin: 0 auto 0 auto;
  background-color: tomato;
}
.Molasses_head-melasa__-sOPC {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 96px;
  opacity: 0.999;
}

@media screen and (max-width: 1050px) {
  .Molasses_head-melasa__-sOPC {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
  }
}
@media screen and (max-width: 630px) {
  .Molasses_head-melasa__-sOPC {
    margin-bottom: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Molasses/Molasses.module.scss"],"names":[],"mappings":"AAAA;EACA,qBAAA;EACA,wBAAA;AACA;AAQA;EACE,+BAAA;EACA,sBAAA;EACA,4BAAA;EACA,mBAAA;EACA,cAAA;AANF;;AASA;EACE;IACE,8DAAA;EANF;AACF;AASA;EACE;IACE,mBAAA;EAPF;AACF","sourcesContent":[".homeWrapper {\nmargin: 0 auto 0 auto;\nbackground-color: tomato  ;\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n  }\n}\n\n.head-melasa {\n  background-position: top center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  margin-bottom: 96px;\n  opacity: 0.999;\n}\n\n@media screen and (max-width: 1050px) {  \n  .head-melasa {\n    background: url('../../media/orange-bg-mobile.svg') !important;\n  }\n}\n\n@media screen and (max-width: 630px) { \n  .head-melasa {\n    margin-bottom: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeWrapper": `Molasses_homeWrapper__qxRUU`,
	"head-melasa": `Molasses_head-melasa__-sOPC`
};
export default ___CSS_LOADER_EXPORT___;
