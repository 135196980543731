import React from "react";
import css from "./Packaging.module.scss";
import Container from "../Container";
// import Title from "../Title/Title";
import { useTranslation } from "react-i18next";

const Packaging = ({ data, type }) => {

  const { t } = useTranslation();
  const container = t(`${data}.container.content`, { returnObjects: true });
  const transport = t(`${data}.transport.content`, { returnObjects: true });
  
  return (
    <Container>
      <div className={css.wrapper}>
        <h2 className={css.title}>{t(`${data}.title`)}</h2>

        <div className={t(`${css.content} ${type}`)}>
          <div className={css.wrapperPacking}>
            <div className={css.wrapperContent}>
              <h3>{t(`${data}.container.title`)}</h3>
              <ul className={css.list_1}>
                {container.map((el, i) => (
                  <li className={css.item_1} key={i}>
                    <img src={el.imgUrl} alt={i} />
                    <p>{el.weight}</p>
                  </li>
                ))}
              </ul>
            </div>

            <div className={css.wrapperContent}>
              <h3>{t(`${data}.transport.title`)}</h3>
              <ul className={css.list_2}>
                {transport.map((el, i) => (
                  <li key={i}>
                    <img src={el.imgUrl} alt={i} />
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className={css.wrapper__description}>
            <h3 className={css.description_title}>{t(`${data}.description.title`)}</h3>

            <div className={css.flexContainer}>
              <div className={css.description_container}>
                <div className={css.description_boxTitle}>
                  <img src={process.env.PUBLIC_URL + t(`${data}.description.internal.imgUrl`)} alt="1" />
                  <h4>{t(`${data}.description.internal.title`)}</h4>
                </div>

                <div className={css.description_decorWrapper}>
                  <p>{t(`${data}.description.internal.description_1`)}</p>
                  <p>{t(`${data}.description.internal.description_2`)}</p>
                </div>
              </div>

              <div className={css.description_container}>
                <div className={css.description_boxTitle}>
                  <img src={process.env.PUBLIC_URL + t(`${data}.description.internal.imgUrl`)} alt="1" />
                  <h4>{t(`${data}.description.external.title`)}</h4>
                </div>
                <div className={css.description_decorWrapper}>
                  <p>{t(`${data}.description.external.description_1`)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Packaging;
