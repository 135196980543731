// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetailsCompost_wrapper__n1fWM {
  margin-bottom: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/DetailsCompost/DetailsCompost.module.scss"],"names":[],"mappings":"AAAA;EAEE,mBAAA;AAAF","sourcesContent":[".wrapper {\n  // border: 1px solid tomato;\n  margin-bottom: 80px;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DetailsCompost_wrapper__n1fWM`
};
export default ___CSS_LOADER_EXPORT___;
