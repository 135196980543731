// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 64px;
  border: 1px solid var(--main-color);
  border-radius: 8px;
  color: var(--main-color);
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
}

.ButtonLocation_activeButton__Tq9GX {
  border: 1px solid rgb(255, 255, 255);
}
.ButtonLocation_activeButton__Tq9GX .ButtonLocation_text_1__-8vit {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}
.ButtonLocation_activeButton__Tq9GX .ButtonLocation_text_2__Su3az {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.ButtonLocation_activeButton__Tq9GX:before {
  position: absolute;
  content: "";
  display: block;
  width: 110px;
  height: 9px;
  border-radius: 0px 0px 4px 4px;
  bottom: -10px;
  left: 18%;
  background-color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/components/ButtonLocation/ButtonLocation.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;EACA,mCAAA;EACA,kBAAA;EACA,wBAAA;EACA,0CAAA;EACA,kBAAA;AACF;;AAGA;EACE,oCAAA;AAAF;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAIE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;EACA,8BAAA;EACA,aAAA;EACA,SAAA;EACA,oCAAA;AAHJ","sourcesContent":["button {\n  background-color: transparent;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 166px;\n  height: 64px;\n  border: 1px solid var(--main-color);\n  border-radius: 8px;\n  color: var(--main-color);\n  border: 1px solid rgba(255, 255, 255, 0.3);\n  position: relative;\n  // min-width: 100px;\n}\n\n.activeButton {\n  border: 1px solid rgba(255, 255, 255);\n\n  .text_1 {\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 16px;\n    // color: #5d9624;\n  }\n\n  .text_2 {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n  }\n\n  &:before {\n    position: absolute;\n    content: \"\";\n    display: block;\n    width: 110px;\n    height: 9px;\n    border-radius: 0px 0px 4px 4px;\n    bottom: -10px;\n    left: 18%;\n    background-color: rgb(255, 255, 255);\n  }\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activeButton": `ButtonLocation_activeButton__Tq9GX`,
	"text_1": `ButtonLocation_text_1__-8vit`,
	"text_2": `ButtonLocation_text_2__Su3az`
};
export default ___CSS_LOADER_EXPORT___;
