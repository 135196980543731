import React from "react";
import css from "./AboutUs.module.scss";
import Container from "../Container";
import Title from "../Title/Title";
import { useTranslation } from "react-i18next";

// import decorImage from '../../../public/media/images/aboutUs/decor_1.png';

const AboutUs = ({ dataAboutUs }) => {

  const { t } = useTranslation();

  // Функція для виділення ТзОВ жирним 
  const formatDescription = (description) => {
    const replacedText = description.replace(/ТзОВ «Радехівський цукор»/g, '<strong><a href="https://www.diamantsugar.com.ua/ua" target="_blank" rel="noopener noreferrer">ТзОВ «Радехівський цукор»</a></strong>');
    return { __html: replacedText };
  };
  

  return (
    <Container>
      <div className={css.wrapper}>
        <div className={css.titleUsWrapper}>

          <Title title={t(dataAboutUs.title)} />
        </div>

        <div className={css.contentWrapper}>
          <div className={css.text}>
            <div className={css.wrapperTitleContent}>
              <Title title={t(dataAboutUs.titleContent)} color="white" />
            </div>


            <p className={css.description_1} dangerouslySetInnerHTML={formatDescription(t("dataAboutUs.description_1"))}></p>
            <p className={css.description_2}>{t(dataAboutUs.description_2)}</p>
          </div>

          <div>
            <img src={dataAboutUs.imgUrl} alt="decor_1" />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AboutUs;
