import React from "react";
import css from "./ForWhat.module.scss";
import Container from "../Container";
import { useTranslation } from "react-i18next";

const ForWhat = ({ data }) => {

  const { t } = useTranslation();

  const content = t(`${data}.content` , { returnObjects: true });

  return (
    <Container>
      <div className={css.wrapper}>
        <h3>{t(`${data}.title`)}</h3>
        <div className={css.inner}>
            {content.map((item, index) => {
                return <div key={index} className={css.block}>
                            <img src={item.img} alt="for"/>
                            <p>{item.text}</p>
                </div>
            })}
        </div>
      </div>
    </Container>
  );
};

export default ForWhat;