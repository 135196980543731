import React from "react";
import css from "./DescriptionCard.module.scss";
import Title from "../Title/Title";
import Container from "../Container";
import { useTranslation } from "react-i18next";

const DescriptionCard = ({ data, style }) => {
  const { t } = useTranslation();

  return (
    <div className={style === 'black' ? css.wrapperBlack : css.wrapper}>
     <p className={css.textTitle}>{t(data.title)}</p>

     <p className={css.textContent}>{t(data.description)}</p>
    </div>
  );
};

export default DescriptionCard;
