import React from "react";
import css from "./Details.module.scss";
import Container from "../Container";
import { useTranslation } from "react-i18next";

const Details = ({ data }) => {

  const { t } = useTranslation();

  const organ = t(`${data}.organ` , { returnObjects: true });
  const him = t(`${data}.him` , { returnObjects: true });

  return (
    <Container>
      <div className={css.wrapper}>
        <div className={css.innerTop}>
        <h4 className={css.title1}>{t(`${data}.title1`)}</h4>
            {organ.map((item, index) => {
                return <div key={index} className={css['block' + index] + ' all'}>{item.title}
                            <span>{item.subtitle}</span>
                       </div>
            })}
        </div>
        <img src={t(`${data}.img`)} alt="dataimg" />
        <div className={css.innerBottom}>
        <h4 className={css.title2}>{t(`${data}.title2`)}</h4>
            {him.map((item, index) => {
                return <div key={index} className={css['block' + (index + organ.length)] + ' all'}>
                             <span>{item.title}</span>
                            {item.subtitle}
                        </div>
            })}
        </div>
      </div>
    </Container>
  );
};

export default Details;