import React from "react";
import css from "./StorageConditions.module.scss";
import Container from "../Container";
import Title from "../Title/Title";
import { useTranslation } from "react-i18next";

const StorageConditions = ({ data }) => {
  const { t } = useTranslation();
  
  return (
    <Container>
      <div className={css.wrapper}>
        <h2 className={css.title}>{t(`${data}.title`)}</h2>

        <div className={css.content}>
          <div className={css.decorBox}>
            <img
              className={css.decor_left}
              src={process.env.PUBLIC_URL + t(`${data}.imgUrlDecor`)}
              alt="decor_1"
            />

            <div className={css.boxHidden}>
              <img src={process.env.PUBLIC_URL + t(`${data}.imgUrl`)} alt="decor_1" />
            </div>

            <img
              className={css.decor_right}
              src={process.env.PUBLIC_URL + t(`${data}.imgUrlDecor`)}
              alt="decor_1"
            />
          </div>

          <p className={css.text}>{process.env.PUBLIC_URL + t(`${data}.text`)}</p>
        </div>
      </div>
    </Container>
  );
};

export default StorageConditions;
