import React from "react";
import css from "./UniversalButton.module.scss";
import { ReactComponent as PlantIcon } from "../../media/icons/plant.svg";

const UniversalButton = ({ textUp, textDown, isActive, id }) => {
  const buttonClasses = `${css.buttonStyle} ${
    isActive ? css.activeButton : ""
  } ${id === 3 ? css.longButton : ""}`;

  return (
    <button className={buttonClasses}>
      <div className={css.iconButtons}>
        {id === 3 ? (
          <PlantIcon style={{ marginRight: "10px", width: "100px" }} />
        ) : (
          ""
        )}

        <span>{textUp}</span>
        <span>{textDown}</span>
      </div>
    </button>
  );
};

export default UniversalButton;
