import React from "react";
import css from "./UniversalModalWindow.module.scss";
import { useTranslation } from "react-i18next";

const UniversalModalWindow = ({ active, setActive, children }) => {
  // console.log('active', active);
  return (
    <>
      <div
        className={active ? `${css.modal} ${css.active}` : css.modal}
        onClick={() => setActive(false)}
      >
        <div
          className={
            active ? `${css.modal__content} ${css.active}` : css.modal__content
          }
          onClick={(e) => e.stopPropagation()}
        >

          {children}
        </div>
      </div>
    </>
  );
};

export default UniversalModalWindow;
