import React, { useState } from "react";

import css from "./ProductCard.module.scss";
import Title from "../Title/Title";
import Container from "../Container";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const ProductCard = ({ data }) => {
  const { t } = useTranslation();

  // console.log(12313123, data);
 
  return (
    <NavLink to={data.path}>
      <div className={css.wrapper}>
        <div className={css.wrapperImg}>
          <img className={css.img} src={data.imgUrl} alt="foto" />
          <h1 className={css.text}>{t(data.title)}</h1>
        </div>

        <button className={css.buttonCard}>{t(data.titleButton)}</button>
      </div>
    </NavLink>
  );
};

export default ProductCard;
