// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Description_wrapper__R9p1y {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  margin-bottom: 70px;
}
.Description_wrapper__R9p1y .Description_wrapperTitle__3EVto .Description_title__c4IGw {
  margin-bottom: 30px;
}
.Description_wrapper__R9p1y .Description_wrapperTitle__3EVto h3 {
  text-align: center;
  margin-bottom: 30px;
}
.Description_wrapper__R9p1y .Description_wrapperTitle__3EVto .Description_wrapperDescription__m4\\+6z {
  display: flex;
  gap: 40px;
}
@media (max-width: 768px) {
  .Description_wrapper__R9p1y .Description_wrapperTitle__3EVto .Description_wrapperDescription__m4\\+6z {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .Description_wrapper__R9p1y .Description_wrapperTitle__3EVto {
    margin-bottom: 100px;
  }
}
@media (max-width: 768px) {
  .Description_wrapper__R9p1y {
    flex-direction: column;
  }
}

.Description_wrapperTitle__3EVto h3 {
  text-align: center;
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/Description/Description.module.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,SAAA;EACA,8BAAA;EACA,mBAAA;AAAF;AAGI;EACE,mBAAA;AADN;AAII;EACE,kBAAA;EACA,mBAAA;AAFN;AAKI;EACE,aAAA;EACA,SAAA;AAHN;AASM;EARF;IASD,sBAAA;EAND;AACF;AAcI;EA5BF;IA6BD,oBAAA;EAXC;AACF;AAiBE;EA3CF;IA4CI,sBAAA;EAdF;AACF;;AAoBE;EACE,kBAAA;EACA,mBAAA;AAjBJ","sourcesContent":[".wrapper {\n  // border: 1px solid black;\n  display: flex;\n  gap: 40px;\n  justify-content: space-between;\n  margin-bottom: 70px;\n\n  .wrapperTitle {\n    .title {\n      margin-bottom: 30px;\n    }\n\n    h3 {\n      text-align: center;\n      margin-bottom: 30px;\n    }\n\n    .wrapperDescription {\n      display: flex;\n      gap: 40px;\n\n\n      @media (max-width: 1360px) {\n      }\n    \n      @media (max-width: 768px) {\n   flex-direction: column;\n      }\n    }\n\n\n\n    @media (max-width: 1360px) {\n    }\n  \n    @media (max-width: 768px) {\n margin-bottom: 100px;\n    }\n  }\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n  }\n}\n\n\n.wrapperTitle {\n\n  h3 {\n    text-align: center;\n    margin-bottom: 30px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Description_wrapper__R9p1y`,
	"wrapperTitle": `Description_wrapperTitle__3EVto`,
	"title": `Description_title__c4IGw`,
	"wrapperDescription": `Description_wrapperDescription__m4+6z`
};
export default ___CSS_LOADER_EXPORT___;
