// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Product_wrapper__wM\\+8n {
  margin-bottom: 70px;
}
.Product_wrapper__wM\\+8n .Product_title__sQ2qS {
  margin-bottom: 40px;
}
.Product_wrapper__wM\\+8n .Product_list__-4Oqj {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Product_wrapper__wM\\+8n .Product_list__-4Oqj li {
  width: 100%;
}
@media (max-width: 768px) {
  .Product_wrapper__wM\\+8n .Product_list__-4Oqj li:first-child {
    margin-bottom: 56px;
  }
}
@media (max-width: 768px) {
  .Product_wrapper__wM\\+8n .Product_list__-4Oqj {
    flex-direction: column;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Product/Product.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EAEE,aAAA;EACA,8BAAA;EACA,mBAAA;AADJ;AAGI;EACE,WAAA;AADN;AAOM;EAHF;IAIM,mBAAA;EAJR;AACF;AAWI;EAtBF;IAuBI,sBAAA;IACA,WAAA;EARJ;AACF","sourcesContent":[".wrapper {\n  margin-bottom: 70px;\n\n  .title {\n    margin-bottom: 40px;\n  }\n\n  .list {\n    // border: 1px solid black;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    li {\n      width: 100%;\n    }\n\n    li:first-child {\n      // margin-bottom: 56px;\n\n      @media (max-width: 768px) {\n          margin-bottom: 56px;\n      }\n\n    }\n\n    @media (max-width: 1360px) {\n    }\n\n    @media (max-width: 768px) {\n      flex-direction: column;\n      width: 100%;\n    }\n  }\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Product_wrapper__wM+8n`,
	"title": `Product_title__sQ2qS`,
	"list": `Product_list__-4Oqj`
};
export default ___CSS_LOADER_EXPORT___;
