// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GoogleMaps_mapWrapper__6ncVw {
  position: relative;
  width: 100%;
  height: 0%;
  min-height: 300px;
  padding-bottom: 20%;
  overflow: hidden;
}
.GoogleMaps_mapWrapper__6ncVw iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  filter: grayscale(100%);
}`, "",{"version":3,"sources":["webpack://./src/components/GoogleMaps/GoogleMaps.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,UAAA;EACD,iBAAA;EACC,mBAAA;EACA,gBAAA;AACF;AACE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EAEA,SAAA;EACA,uBAAA;AAAJ","sourcesContent":[".mapWrapper {\n  position: relative;\n  width: 100%;\n  height: 0%;\n min-height: 300px;\n  padding-bottom: 20%; \n  overflow: hidden;\n\n  iframe {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n\n    border: 0;\n    filter: grayscale(100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapWrapper": `GoogleMaps_mapWrapper__6ncVw`
};
export default ___CSS_LOADER_EXPORT___;
