import React, { useState } from "react";
import css from "./Product.module.scss";
import Title from "../Title/Title";
import Container from "../Container";
import { useTranslation } from "react-i18next";
import ProductCard from "../ProductCard";

const Product = ({ dataProduct }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <div className={css.wrapper}>
        <div className={css.title}>
          {/* <Title title={data.title} /> */}
          <Title title={t(dataProduct.title)} />
        </div>

        <ul className={css.list}>
          {dataProduct.content.map((el, i) => (
            <li key={i}>
              <ProductCard data={el} />
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
};

export default Product;
