// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DescriptionCard_wrapper__wRjTo {
  border: 1px solid #454545;
  padding: 32px 20px 32px 20px;
  width: 355px;
  border-radius: 16px;
}
.DescriptionCard_wrapper__wRjTo .DescriptionCard_textTitle__ys-6U {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.DescriptionCard_wrapper__wRjTo .DescriptionCard_textContent__p2I3K {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@media (max-width: 768px) {
  .DescriptionCard_wrapper__wRjTo {
    width: 100%;
  }
}

.DescriptionCard_wrapperBlack__XYG8B {
  border: 1px solid #454545;
  padding: 32px 20px 32px 20px;
  width: 355px;
  border-radius: 16px;
  background-color: #454545;
}
.DescriptionCard_wrapperBlack__XYG8B .DescriptionCard_textTitle__ys-6U {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: white;
}
.DescriptionCard_wrapperBlack__XYG8B .DescriptionCard_textContent__p2I3K {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: white;
}
@media (max-width: 768px) {
  .DescriptionCard_wrapperBlack__XYG8B {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/DescriptionCard/DescriptionCard.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,4BAAA;EAEA,YAAA;EACA,mBAAA;AAAF;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAOE;EAtBF;IAuBI,WAAA;EAJF;AACF;;AAQA;EACE,yBAAA;EACA,4BAAA;EAEA,YAAA;EACA,mBAAA;EACA,yBACA;AAPF;AASE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;AAPJ;AAUE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;AARJ;AAcE;EA1BF;IA2BI,WAAA;EAXF;AACF","sourcesContent":[".wrapper {\n  border: 1px solid #454545;\n  padding: 32px 20px 32px 20px;\n\n  width: 355px;\n  border-radius: 16px;\n\n  .textTitle {\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 24px;\n  }\n\n  .textContent {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n  }\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n    width: 100%;\n  }\n}\n\n\n.wrapperBlack {\n  border: 1px solid #454545;\n  padding: 32px 20px 32px 20px;\n\n  width: 355px;\n  border-radius: 16px;\n  background-color: \n  #454545;\n\n  .textTitle {\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 24px;\n    color: white;\n  }\n\n  .textContent {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    color: white;\n  }\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DescriptionCard_wrapper__wRjTo`,
	"textTitle": `DescriptionCard_textTitle__ys-6U`,
	"textContent": `DescriptionCard_textContent__p2I3K`,
	"wrapperBlack": `DescriptionCard_wrapperBlack__XYG8B`
};
export default ___CSS_LOADER_EXPORT___;
