// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Title_titleText__xG3wj {
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  color: var(--text-color-black);
}
.Title_titleTextColor__\\+PhMJ {
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  color: var(--text-color-white);
}
@media screen and (max-width: 630px) {
  .Title_titleText__xG3wj {
    font-size: 24px;
    line-height: 32px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Title/Title.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EAGA,8BAAA;AADF;AAYA;EACE,eAAA;EACF,gBAAA;EACA,iBAAA;EAEE,8BAAA;AAXF;AAsBA;EACE;IACE,eAAA;IACA,iBAAA;EApBF;AACF","sourcesContent":[".titleText {\n  font-size: 32px;\n  font-weight: 700;\n  line-height: 32px;\n\n  \n  color: var(--text-color-black);\n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n\n  }\n}\n\n.titleTextColor {\n  font-size: 32px;\nfont-weight: 700;\nline-height: 32px;\n\n  color: var(--text-color-white);\n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n\n  }\n}\n\n@media screen and (max-width: 630px) { \n  .titleText{\n    font-size: 24px;\n    line-height: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleText": `Title_titleText__xG3wj`,
	"titleTextColor": `Title_titleTextColor__+PhMJ`
};
export default ___CSS_LOADER_EXPORT___;
