import React from "react";
import css from "./MainDescription.module.scss";
import Container from "../Container";
// import Title from "../Title/Title";
import { useTranslation } from "react-i18next";

const MainDescription = ({ data }) => {

  const { t } = useTranslation();

  return (
    <Container>
      <div className={css.wrapper}>
        <h2 className={css.title}>{t(`${data}.title`)}</h2>
  
          <p className={css.text}>
            <span className={css.decor}>{t(`${data}.description_1`)}</span>{t(`${data}.description_2`)}
          </p>

      </div>
    </Container>
  );
};

export default MainDescription;
