// LanguageSwitcher.jsx
import React, { useState } from "react";
import { useTranslation } from 'react-i18next'; // Додано імпорт
import css from './LanguageSwitcher.module.scss'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation(); // Отримання об'єкта i18n з хука

  const [activeLanguage, setActiveLanguage] = useState("UA");

  const switchLanguage = (language) => {
    setActiveLanguage(language);
    i18n.changeLanguage(language.toLowerCase()); // Зміна мови через i18n
  };




  return (
    <div className={css.wrapperLanguage}>
      <button
        className={`${css.langButton} ${activeLanguage === 'UA' ? css.activeLang : ''}`}
        onClick={() => switchLanguage("UA")}
      >
        UA
      </button>
      <button
        className={`${css.langButton} ${activeLanguage === 'EN' ? css.activeLang : ''}`}
        onClick={() => switchLanguage("EN")}
      >
        EN
      </button>
      <button
        className={`${css.langButton} ${activeLanguage === 'DE' ? css.activeLang : ''}`}
        onClick={() => switchLanguage("DE")}
      >
        DE
      </button>
    </div>
  );
};

export default LanguageSwitcher;
