import React from "react";
import css from "./Navigation.module.scss";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import UniversalButton from "../UniversalButton";

const Navigation = ({ showStyle, list }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  // console.log(location);

  return (
    <nav
      className={
        showStyle === "line" ? css.wrapperNavLine : css.wrapperNavColumn
      }
    >
      <ul className={css.list}>
        {list.map((el, i) => (
          <li key={i} className={css.item}>
            <NavLink to={el.path} className={css.buttonLink}>
              <UniversalButton
                className={css.buttonLink}
                textUp={t(el.name)}
                isActive={location.pathname === el.path}
                id={el.id}
              />
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
