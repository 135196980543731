import React, { useEffect, useState } from "react";
import css from "./Contacts.module.scss";
import Title from "../Title/Title";
import Container from "../Container";
import GoogleMaps from "../GoogleMaps";
import SimpleSlider from "../SimpleSlider/SimpleSlider";
import ButtonLocation from "../ButtonLocation";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const Contacts = ({ data }) => {
  const [activeToggle, setActiveToggle] = useState();
  const { t } = useTranslation();
  const arrLocations = t(`${data}.arrLocations`, { returnObjects: true });

  const [activeButton, setActiveButton] = useState(
    arrLocations[0].title_1
  );


  useEffect(() => {
  // console.log('activeToggle', activeToggle);
  }, [activeToggle])


  const location = useLocation();
  // console.log(location.pathname);

  useEffect (() => {
    if (location.pathname === "/") {
      setActiveToggle(t(`${data}.name_1`))
    }
    if (location.pathname === "/compost") {
      setActiveToggle(t(`${data}.name_1`))
    }
    if (location.pathname === "/molasses") {
      setActiveToggle(t(`${data}.name_2`))
    }

  },[])


  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const activeContent = arrLocations.find(
    (el) => el.title_1 === activeButton
  );

  const handleToggleClick = (buttonName) => {
    setActiveToggle(buttonName);
  };

  return (
    <Container>
      <div className={css.wrapper}>
        <div className={css.title}>

    {activeToggle=== t(`${data}.name_1`) ?   <Title title={t(`${data}.title_1`)} /> :  <Title title={t(`${data}.title_2`)} /> }


        
        </div>


{location.pathname === "/" ? <div className={css.wrapperButton}>
      <button
        className={`${css.toggleButton_left} ${activeToggle === t(`${data}.name_1`) ? css.activeToggle : ''}`}
        onClick={() => handleToggleClick(t(`${data}.name_1`))}
      >
        {t(`${data}.name_1`)}
      </button>
      <button
        className={`${css.toggleButton_right} ${activeToggle === t(`${data}.name_2`) ? css.activeToggle : ''}`}
        onClick={() => handleToggleClick(t(`${data}.name_2`))}
      >
        {t(`${data}.name_2`)}
      </button>
    </div> : ''}
        



        <div className={css.wrapperContent}>
          <div className={css.decorBox}>
            <ul className={css.list}>
              {arrLocations.map((el, i) => (
                (el.packagingCompost !== undefined && el.packagingMolas !== undefined) &&
                
                <li className={css.item} key={i}>
                  <ul className={css.list_1}>

                    {location.pathname === "/compost" || activeToggle === t(`${data}.name_1`)
                      ? el.packagingCompost.map((element, i) => (
                          <li className={css.item_1} key={i}>
                            <img src={process.env.PUBLIC_URL + element.imgUrl} alt={element.weight} />
                            <p>{element.weight}</p>
                          </li>
                        ))
                      : el.packagingMolas.map((element, i) => (
                          <li className={css.item_1} key={i}>
                            <img src={process.env.PUBLIC_URL + element.imgUrl} alt={element.weight} />
                            <p>{element.weight}</p>
                          </li>
                        ))}
                    
                  </ul>

                  <ButtonLocation
                    objData={el}
                    shangState={handleButtonClick}
                    activeButton={activeButton}
                  />
                </li>
                
              ))}
            </ul>

            <div className={css.wrapperSlider}>
            {/* {arrLocations != undefined &&
              <SimpleSlider
                dataSlider={arrLocations}
                shangState={handleButtonClick}
                activeButton={activeButton}
              />} */}
            </div>
          </div>

          <div className={css.wrapperMaps}>
            {activeContent && <GoogleMaps path={activeContent.location} />}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Contacts;
