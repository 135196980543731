import React, { useState } from "react";

import css from "./Description.module.scss";
import Title from "../Title/Title";
import Container from "../Container";
import { useTranslation } from "react-i18next";
import DescriptionCard from "../DescriptionCard/DescriptionCard";

const Description = ({ dataDescription }) => {

  const { t } = useTranslation();

  return (
    <Container>
      <div className={css.wrapperTitle}>
        <Title title={t("dataDescription.title_1")} />
      </div>
      <div className={css.wrapper}>
        <div className={css.wrapperTitle}>
          <DescriptionCard data={dataDescription.message.obj_1} style="black" />
        </div>
        <div className={css.wrapperTitle}>
          <div className={css.wrapperDescription}>
            <DescriptionCard data={dataDescription.message.obj_2} />
            <DescriptionCard data={dataDescription.message.obj_3} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Description;
