import React from "react";
import css from "./QuestionForm.module.scss";

const QuestionForm = ( {setActive1, setActive2} ) => {



const openButton = () => {
  setActive2(true)
  setActive1(false)
}

const closeButton = () => {

  setActive1(false)
}

  return (
    <div className={css.questionForm}>
      <h3 className={css.formTitle}>Залишити заявку</h3>

      <p className={css.formDescription}>Умови співпраці:</p>

      <ul className={css.formList}>
        <ol>Оплата за продукцію тільки по перерахунку.</ol>
        <ol>Договірна діяльність підписання тільки електронно-цифровим підписом.</ol>
      </ul>

      <div className={css.buttonContainer}>
        <button className={css.submitButton} onClick={openButton}>Оформити заявку</button>
        <button className={css.cancelButton} onClick={closeButton}>Відміна</button>
      </div>
    </div>
  );
};

export default QuestionForm;
