// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* У файлі QuestionForm.module.scss */
.QuestionForm_questionForm__MNq27 {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.QuestionForm_formTitle__3DPjw {
  font-size: 24px;
  margin-bottom: 16px;
}

.QuestionForm_formDescription__7bZWi {
  font-size: 16px;
  margin-bottom: 12px;
}

.QuestionForm_formList__MFkVk {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}
.QuestionForm_formList__MFkVk li {
  margin-bottom: 8px;
}

.QuestionForm_buttonContainer__RbbHk {
  display: flex;
  justify-content: space-between;
}
.QuestionForm_buttonContainer__RbbHk button {
  flex: 1 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.QuestionForm_buttonContainer__RbbHk button:hover {
  background-color: #aebeaf;
}
.QuestionForm_buttonContainer__RbbHk .QuestionForm_submitButton__LagcD {
  background-color: #4caf50;
  color: white;
  margin-right: 8px;
}
.QuestionForm_buttonContainer__RbbHk .QuestionForm_cancelButton__eMsaJ {
  background-color: #f44336;
  color: white;
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/QuestionForm/QuestionForm.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB,qCAAA;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,qBAAA;EACA,UAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;AACJ;;AAGA;EACE,aAAA;EACA,8BAAA;AAAF;AAEE;EACE,SAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,sCAAA;AAAJ;AAEI;EACE,yBAAA;AAAN;AAIE;EACE,yBAAA;EACA,YAAA;EACA,iBAAA;AAFJ;AAKE;EACE,yBAAA;EACA,YAAA;EACA,gBAAA;AAHJ","sourcesContent":["/* У файлі QuestionForm.module.scss */\n\n.questionForm {\n  text-align: center;\n  max-width: 400px;\n  margin: 0 auto;\n}\n\n.formTitle {\n  font-size: 24px;\n  margin-bottom: 16px;\n}\n\n.formDescription {\n  font-size: 16px;\n  margin-bottom: 12px;\n}\n\n.formList {\n  list-style-type: none;\n  padding: 0;\n  margin-bottom: 20px;\n\n  li {\n    margin-bottom: 8px;\n  }\n}\n\n.buttonContainer {\n  display: flex;\n  justify-content: space-between;\n\n  button {\n    flex: 1;\n    padding: 10px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n\n    &:hover {\n      background-color: #aebeaf;\n    }\n  }\n\n  .submitButton {\n    background-color: #4caf50;\n    color: white;\n    margin-right: 8px;\n  }\n\n  .cancelButton {\n    background-color: #f44336;\n    color: white;\n    margin-left: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questionForm": `QuestionForm_questionForm__MNq27`,
	"formTitle": `QuestionForm_formTitle__3DPjw`,
	"formDescription": `QuestionForm_formDescription__7bZWi`,
	"formList": `QuestionForm_formList__MFkVk`,
	"buttonContainer": `QuestionForm_buttonContainer__RbbHk`,
	"submitButton": `QuestionForm_submitButton__LagcD`,
	"cancelButton": `QuestionForm_cancelButton__eMsaJ`
};
export default ___CSS_LOADER_EXPORT___;
