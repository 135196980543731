import React from "react";
import css from "./Partners.module.scss";
import Title from "../Title/Title";
import Container from "../Container";
import { useTranslation } from "react-i18next";
// import ProductCard from "../ProductCard";

const Partners = ({ data }) => {
  // console.log(data);
  const { t } = useTranslation();
  const partners = t(`${data}.partners`, { returnObjects: true });

  return (
    <Container>
      <div className={css.wrapper}>
        <div className={css.title}>
          <Title title={t(`${data}.title`)} />
        </div>

        <ul className={css.list}>
          {partners.map((el, i) => (
            <li className={css.item} key={i}>
              <img className={css.imgNormal} src={el.imgUrl} alt={el.title} />
              <img
                className={css.imgHover}
                src={el.imgUrlColor}
                alt={el.title}
              />
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
};

export default Partners;
