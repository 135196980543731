import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";
import './slider.scss';
import Container from '../Container';

import { useTranslation } from "react-i18next";

export const MainSlider = ({slideData}) => {
    // console.log('slideData', slideData);
const { t, i18n } = useTranslation();
const slides = t(slideData, { returnObjects: true });

  return (
    <Container>
    <Swiper
        direction={'vertical'}
        slidesPerView={1}
        spaceBetween={30}
        grabCursor = {true}
        // initialSlide={0}
        pagination={{
        clickable: true,
        }}
        navigation={true}  
        modules={[Pagination, Navigation]}
        className="slider-main"
    >
        {slides !== undefined && slides.map((slide, index)=> {
            return <SwiperSlide key={index}>
                        <div className="container">
                        <div className="main__inner-content" style={{wordBreak: i18n.language === 'de' ? 'break-all' : 'initial'}}>
                            <h1 className='slide-title'>
                                {slide.title} </h1>
                            <h5 className='slide-subtitle'>
                            {slide.subtitle} </h5>
                        </div>
                        {/* <img src={slide.media} width="100%"height="100%"/> */}
                            <video
                                // ref={slide.media}
                                width="100%"
                                height="100%"
                                preload="auto"
                                muted
                                autoPlay
                                playsInline
                                loop
                                >
                                <source src={slide.media} type="video/mp4" />
                            </video>
                        </div>
            </SwiperSlide>           
        })}
  </Swiper>
  </Container>
  );
};
