import React from "react";
import css from "./DetailsCompost.module.scss";
import Container from "../Container";
import DetailsCompostDescTop from "../DetailsCompostDescTop";
import DetailsCompostMobile from "../DetailsCompostMobile";
// import { useTranslation } from "react-i18next";

const DetailsCompost = ({ data }) => {
  //   const { t, i18n } = useTranslation();

  return (
   <>
   <div className={css.wrapper}>

   <DetailsCompostDescTop data={data}/>
    <DetailsCompostMobile data={data}/>
   </div>

   </>
  );
};

export default DetailsCompost;
