import React from "react";

import css from "./Info.module.scss";
import Container from "../Container";
import { useTranslation } from "react-i18next";

const Info = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <div className={css.wrapper}>
        <div className={css.wrapperDecor_1}>
        <img src={t(`${data}.imgUrl`)} alt="decor_1" />
        </div>

        <div className={css.wrapperText}>
          <h3 className={css.title}>{t(`${data}.title`)}</h3>
          <p className={css.number}>{t(`${data}.number`)}</p>
          {/* <p className={css.name}>{t(`${data}.name`)}</p> */}
        </div>

        <div className={css.wrapperDecor_2}>
          <img src={t(`${data}.imgUrl`)} alt="decor_1" />
        </div>
      </div>
    </Container>
  );
};

export default Info;
