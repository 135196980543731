// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo_wrapper__aH5ni {
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.Logo_wrapper__aH5ni .Logo_icon__0ARj8 {
  margin-right: 13px;
}
.Logo_wrapper__aH5ni .Logo_text__Y7ei9 {
  font-weight: 900;
  white-space: nowrap;
}
@media screen and (max-width: 630px) {
  .Logo_wrapper__aH5ni {
    margin: 0 auto;
  }
  .Logo_wrapper__aH5ni svg {
    width: 100px;
    height: 50px;
  }
  .Logo_wrapper__aH5ni .Logo_icon__0ARj8 {
    margin-right: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,gBAAA;EACA,mBAAA;AAAJ;AAYA;EACE;IACE,cAAA;EAVF;EAWE;IACE,YAAA;IACA,YAAA;EATJ;EAWE;IACE,eAAA;EATJ;AACF","sourcesContent":[".wrapper {\n  color: rgb(255, 255, 255);\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n\n  .icon {\n    margin-right: 13px;\n  }\n\n  .text {\n    font-weight: 900;\n    white-space: nowrap;\n  }\n  \n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n  }\n}\n\n@media screen and (max-width: 630px) { \n  .wrapper {\n    margin: 0 auto;\n    svg {\n      width: 100px;\n      height: 50px;\n    }\n    .icon {\n      margin-right: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Logo_wrapper__aH5ni`,
	"icon": `Logo_icon__0ARj8`,
	"text": `Logo_text__Y7ei9`
};
export default ___CSS_LOADER_EXPORT___;
