// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductCard_wrapper__9el3C {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.ProductCard_wrapper__9el3C .ProductCard_wrapperImg__AZt6K {
  border: 1px solid rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 540px;
  height: 300px;
  border-radius: 16px;
  border-color: #454545;
}
.ProductCard_wrapper__9el3C .ProductCard_wrapperImg__AZt6K .ProductCard_img__ONbyh {
  position: relative;
  top: 0;
  transition: top 0.3s ease, transform 0.3s ease;
}
.ProductCard_wrapper__9el3C .ProductCard_wrapperImg__AZt6K:hover .ProductCard_img__ONbyh {
  transform: scale(1.2);
  top: -45px;
}
.ProductCard_wrapper__9el3C .ProductCard_wrapperImg__AZt6K .ProductCard_text__4dF0Y {
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 16px;
  margin-top: auto;
}
@media (max-width: 768px) {
  .ProductCard_wrapper__9el3C .ProductCard_wrapperImg__AZt6K {
    width: 100%;
  }
}
.ProductCard_wrapper__9el3C .ProductCard_buttonCard__SpsQd {
  width: 216px;
  height: 40px;
  width: 200px;
  height: 40px;
  border-radius: 0px 0px 8px 8px;
  background-color: #454545;
  color: var(--main-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@media (max-width: 768px) {
  .ProductCard_wrapper__9el3C {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProductCard/ProductCard.module.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;AAAF;AAEE;EACE,8BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EAEA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;AADJ;AAGI;EAIE,kBAAA;EACA,MAAA;EACA,8CAAA;AAJN;AAUM;EACE,qBAAA;EACA,UAAA;AARR;AAWI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AATN;AAeI;EAvCF;IAwCI,WAAA;EAZJ;AACF;AAeE;EACE,YAAA;EACA,YAAA;EAEA,YAAA;EACA,YAAA;EAEA,8BAAA;EACA,yBAAA;EACA,wBAAA;EAEA,eAAA;EACA,gBAAA;EACA,iBAAA;AAhBJ;AAsBE;EAtEF;IAuEI,WAAA;EAnBF;AACF","sourcesContent":[".wrapper {\n  // border: 1px solid black;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  position: relative;\n\n  .wrapperImg {\n    border: 1px solid rgb(0, 0, 0);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    width: 540px;\n    height: 300px;\n    border-radius: 16px;\n    border-color: #454545;\n\n    .img {\n      // position: absolute;\n      // top: -45px;\n\n      position: relative; \n      top: 0; \n      transition: top 0.3s ease, transform 0.3s ease; \n\n\n    }\n\n    &:hover {\n      .img {\n        transform: scale(1.2);\n        top: -45px; \n      }\n    }\n    .text {\n      font-size: 32px;\n      font-weight: 700;\n      line-height: 24px;\n      margin-bottom: 16px;\n      margin-top: auto;\n    }\n\n    @media (max-width: 1360px) {\n    }\n\n    @media (max-width: 768px) {\n      width: 100%;\n    }\n  }\n\n  .buttonCard {\n    width: 216px;\n    height: 40px;\n\n    width: 200px;\n    height: 40px;\n\n    border-radius: 0px 0px 8px 8px;\n    background-color: #454545;\n    color: var(--main-color);\n\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n  }\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ProductCard_wrapper__9el3C`,
	"wrapperImg": `ProductCard_wrapperImg__AZt6K`,
	"img": `ProductCard_img__ONbyh`,
	"text": `ProductCard_text__4dF0Y`,
	"buttonCard": `ProductCard_buttonCard__SpsQd`
};
export default ___CSS_LOADER_EXPORT___;
