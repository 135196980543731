import React from "react";
import css from "./DetailsCompostDescTop.module.scss";
import Container from "../Container";
import { useTranslation } from "react-i18next";

const DetailsCompostDescTop = ({ data }) => {;
  const { t } = useTranslation();
  const organ = t(`${data}.organ` , { returnObjects: true });
  const him = t(`${data}.him` , { returnObjects: true });
  
  return (
    <Container>
      <div className={css.wrapper}>
        <div className={css.wrapper_Title}>
          <h4 className={css.title1}>{t(`${data}.title1`)}</h4>
          <h4 className={css.title2}>{t(`${data}.title2`)}</h4>
        </div>

        <div className={css.wrapper_Content}>
          <img src={t(`${data}.img`)} alt="dataimg" />

          {/* <div className={css.wrapper_Organ}> */}
            {organ.map((item, index) => (
              <div key={index} className={css["block_Organ_" + index]}>
                <span>{item.title}</span>
                <p>{item.subtitle}</p>
              </div>
            ))}
          {/* </div> */}

          {/* <div className={css.wrapper_Him}> */}
            {him.map((item, index) => (
              <div key={index} className={css["block_Him_" + index]}>
                <span>{item.title}</span>
                <p>{item.subtitle}</p>
              </div>
            ))}
          {/* </div> */}
        </div>
      </div>
    </Container>
  );
};

export default DetailsCompostDescTop;
