// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_container__MM274 {
  margin-left: auto;
  margin-right: auto;
  padding: 0 120px 0 120px;
  max-width: 1920px;
  position: relative;
}
@media (max-width: 1040px) {
  .Container_container__MM274 {
    padding: 0 24px 0 24px;
  }
}
@media (max-width: 767px) {
  .Container_container__MM274 {
    padding: 0 16px 0 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Container/Container.module.scss"],"names":[],"mappings":"AAAA;EAEE,iBAAA;EACA,kBAAA;EACA,wBAAA;EACA,iBAAA;EAEA,kBAAA;AADF;AAOE;EAbF;IAcI,sBAAA;EAJF;AACF;AAME;EAjBF;IAkBI,sBAAA;EAHF;AACF","sourcesContent":[".container {\n// border: 1px solid tomato;\n  margin-left: auto;\n  margin-right: auto;\n  padding: 0 120px 0 120px;\n  max-width: 1920px;\n  // background-color: var(--primary-color);\n  position: relative;\n\n  @media (max-width: 1920px) {\n\n  }\n\n  @media (max-width: 1040px) {\n    padding: 0 24px 0 24px;\n  }\n\n  @media (max-width: 767px) {\n    padding: 0 16px 0 16px;\n  }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__MM274`
};
export default ___CSS_LOADER_EXPORT___;
