import React, { useEffect, useState } from "react";
import css from "./FormApplication.module.scss";
import { useTranslation } from "react-i18next";
// import Container from "../Container";
import { IoIosCloseCircleOutline } from "react-icons/io";
import axios from "axios";

const FormApplication = ({ orderForm, closeForm }) => {
  const { t } = useTranslation();

  // {t(`${data}.textButton`)}

  const [formData, setFormData] = useState({
    option: "", // Варіант співпраці
    additionalText: "", // Додатковий текст
    optionProduct: "", // Варіант продукту
    optionPackaging: "", // Варіант упаковки
    addWeight: "", //Кількість
    description: "", //Опис
    phone: "", //Телефон
  });

  const [showThank, setShowThank] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("FormData:", formData);
   
  };

  const handleClickSendForm = () => {
    setShowThank(false);
    axios.post(process.env.REACT_APP_SERVER_URL + '/send-mail', formData ).then((response) => {
      return response;
    }).catch((e) => {
      console.log(e);
    }); 
  };

  const handleClickCloseForm = () => {
    closeForm(false);

  

  };

  return (
    // <Container>
    <div className={css.wrapper}>
      {/* <h3 className={css.titleForm}>{data.title}</h3> */}
      <h3 className={css.titleForm}>{t(orderForm.title)}</h3>

      {showThank ? (
        <form onSubmit={handleSubmit}>
          <p className={css.termsCooperation__title}>
            {t(orderForm.termsCooperation.title)}
            {/* {t(`${data}.termsCooperation.title`)} */}
          </p>
          <ul className={css.termsCooperation__list}>
            {orderForm.termsCooperation.arrContent.map((el, i) => (
              <li className={css.termsCooperation__item} key={i}>
                <label
                  className={
                    formData.option === `option${i}` ? css.selected : ""
                  }
                >
                  <input
                    type="radio"
                    name="option"
                    value={`option${i}`}
                    checked={formData.option === `option${i}`}
                    onChange={handleChange}
                  />
                  <p className={css.termsCooperation__text_1}>{t(el.title)} </p>
                  <p className={css.termsCooperation__text_2}>
                    {t(el.description)}
                  </p>
                </label>
              </li>
            ))}
          </ul>

          <input
            className={css.nameCompany}
            type="text"
            name="additionalText"
            value={formData.additionalText}
            onChange={handleChange}
            placeholder={t(orderForm.title_6)}
          />

          <div className={css.wrapper__infoProduct}>
            <div className={css.product}>
              <p className={css.product__title}>
                {t(orderForm.typeProduct.title)}
              </p>
              <ul className={css.product__list}>
                {orderForm.typeProduct.products.map((el, i) => (
                  <li className={css.product__item} key={i}>
                    <label
                      className={
                        formData.optionProduct === `optionProduct${i + 1}`
                          ? css.selected
                          : ""
                      }
                    >
                      <input
                        type="radio"
                        name="optionProduct"
                        value={`optionProduct${i + 1}`}
                        checked={
                          formData.optionProduct === `optionProduct${i + 1}`
                        }
                        onChange={handleChange}
                      />
                      <div className={css.boxImg}>
                        <img src={el.media} alt="фото" />
                      </div>
                      <p>{t(el.name)}</p>
                    </label>
                  </li>
                ))}
              </ul>
            </div>

            <div className={css.product}>
              <p className={css.product__title}>
                {t(orderForm.typeProduct.typePackaging.title)}
              </p>
              <ul className={css.product__list}>
                {orderForm.typeProduct.typePackaging[
                  formData.optionProduct === "optionProduct1"
                    ? "arrPackaging_1"
                    : "arrPackaging_2"
                ].map((el, i) => (
                  <li className={css.product__item} key={i}>
                    <label
                      className={
                        formData.optionPackaging === `optionPackaging${i + 1}`
                          ? css.selected
                          : ""
                      }
                    >
                      <input
                        type="radio"
                        name="optionPackaging"
                        value={`optionPackaging${i + 1}`}
                        checked={
                          formData.optionPackaging === `optionPackaging${i + 1}`
                        }
                        onChange={handleChange}
                      />
                      <div className={css.boxImg}>
                        <p>{t(el.name)}</p>
                      </div>
                    </label>
                  </li>
                ))}
              </ul>
            </div>

            {/* Об’єми закупівлі */}
            <div className={css.product}>
              <p className={css.product__title}>
                {/* умова в чому фасування */}
                {formData.optionProduct === "optionProduct1"
                  ? t("orderForm.typeProduct.typeContainer.title_1")
                  : t("orderForm.typeProduct.typeContainer.title_2")}
              </p>
              <ul className={css.product__list}>
                {orderForm.typeProduct.typeContainer[
                  formData.optionProduct === "optionProduct1"
                    ? "arrContainer_1"
                    : "arrContainer_2"
                ].map((el, i) => (
                  <li className={css.product__item} key={i}>
                    <label
                      className={
                        formData.addWeight === `addWeight${i + 1}`
                          ? css.selected
                          : ""
                      }
                    >
                      <input
                        type="radio"
                        name="addWeight"
                        value={`addWeight${i + 1}`}
                        checked={formData.addWeight === `addWeight${i + 1}`}
                        onChange={handleChange}
                      />
                      <div className={css.boxImg}>
                        <p>{t(el.name)}</p>
                      </div>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className={css.wrapper__description}>
            <label className={css.description}>
              <p>{t(orderForm.title_4)}</p>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Опис"
              />
            </label>

            <label className={css.description}>
              <p>{t(orderForm.title_5)}</p>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                pattern="[0-9+]*"
                placeholder="+38 096 583 67 68"
              />
            </label>
          </div>

          <div className={css.wrapperButton}>
            <button
              onClick={handleClickSendForm}
              className={css.button__submit}
              type="submit"
            >
              <p>{t(orderForm.title_3)}</p>
            </button>

            <button
              onClick={handleClickCloseForm}
              className={css.button__cancel}
            >
              {/* <p>Відміна</p> */}
              <IoIosCloseCircleOutline className={css.button__icon} />
            </button>
          </div>
        </form>
      ) : (
        <div className={css.thankBlock}>
          <img src={orderForm.imgUrl} alt="decor_1" />
          <p>{t("orderForm.title_2")}</p>
        </div>
      )}
    </div>
    // </Container>
  );
};

export default FormApplication;
