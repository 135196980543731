// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navigation_wrapperNavLine__q5Rb2 {
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  margin-right: auto;
}
.Navigation_wrapperNavLine__q5Rb2 .Navigation_list__hY2hZ {
  display: flex;
  align-items: center;
}
.Navigation_wrapperNavLine__q5Rb2 .Navigation_list__hY2hZ .Navigation_item__PQCMi {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
}
.Navigation_wrapperNavLine__q5Rb2 .Navigation_list__hY2hZ .Navigation_item__PQCMi:not(:last-child) {
  margin-right: 24px;
}
.Navigation_wrapperNavColumn__cCfaS {
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
}
.Navigation_wrapperNavColumn__cCfaS .Navigation_list__hY2hZ {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.Navigation_wrapperNavColumn__cCfaS .Navigation_list__hY2hZ .Navigation_item__PQCMi {
  font-size: 20px;
  font-weight: 900;
  line-height: 25px;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .Navigation_wrapperNavColumn__cCfaS {
    align-items: start;
  }
}

@media screen and (max-width: 630px) {
  .Navigation_wrapperNavLine__q5Rb2 .Navigation_list__hY2hZ .Navigation_item__PQCMi:not(:last-child) {
    margin-right: 10px;
  }
}
.Navigation_buttonLink__6\\+FI5 button {
  min-width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation/Navigation.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACC;EACC,aAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACD,iBAAA;EACA,mBAAA;AACH;AAEE;EACE,kBAAA;AAAJ;AAeA;EACE,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AAbF;AAeC;EACC,aAAA;EACA,sBAAA;EACA,SAAA;AAbF;AAiBE;EAEE,eAAA;EACA,gBAAA;EACA,iBAAA;EAGD,mBAAA;AAlBH;AA+BE;EAlCF;IAmCI,kBAAA;EA5BF;AACF;;AA+BA;EAGM;IACE,kBAAA;EA9BN;AACF;AAoCE;EACE,gBAAA;AAlCJ","sourcesContent":[".wrapperNavLine {\n  color: rgb(255, 255, 255);\n  display: flex;\n  align-items: center;\n  margin-right: auto;\n\n .list {\n  display: flex;\n  align-items: center;\n\n  .item {\n    font-weight: 700;\n    font-size: 16px;\n   line-height: 20px;\n   white-space: nowrap;\n  }\n\n  .item:not(:last-child) {\n    margin-right: 24px;\n}\n }\n\n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n  }\n}\n\n\n\n.wrapperNavColumn {\n  color: rgb(255, 255, 255);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-right: auto;\n\n .list {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n\n  // align-items: center;\n\n  .item {\n\n    font-size: 20px;\n    font-weight: 900;\n    line-height: 25px;\n\n    \n   white-space: nowrap;\n  }\n\n//   .item:not(:last-child) {\n//     margin-right: 24px;\n// }\n }\n\n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n    align-items: start;\n  }\n}\n\n@media screen and (max-width: 630px) {  \n  .wrapperNavLine {\n    .list {\n      .item:not(:last-child) {\n        margin-right: 10px;\n      }\n    }\n  }\n}\n\n.buttonLink {\n  button {\n    min-width: 100px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperNavLine": `Navigation_wrapperNavLine__q5Rb2`,
	"list": `Navigation_list__hY2hZ`,
	"item": `Navigation_item__PQCMi`,
	"wrapperNavColumn": `Navigation_wrapperNavColumn__cCfaS`,
	"buttonLink": `Navigation_buttonLink__6+FI5`
};
export default ___CSS_LOADER_EXPORT___;
