// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainDescription_wrapper__U-GDs {
  margin-bottom: 80px;
}
.MainDescription_wrapper__U-GDs .MainDescription_title__nuBPI {
  align-items: center;
  text-align: center;
  width: 200px;
  height: 40px;
  background-color: #363636;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: center;
}
.MainDescription_wrapper__U-GDs .MainDescription_text__V9Nhg {
  text-align: center;
  border: 1px solid #363636;
  padding: 25px 190px;
  border-radius: 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
.MainDescription_wrapper__U-GDs .MainDescription_text__V9Nhg .MainDescription_decor__AUtt5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}
@media screen and (max-width: 1050px) {
  .MainDescription_wrapper__U-GDs .MainDescription_text__V9Nhg {
    padding: 25px 50px;
  }
}
@media screen and (max-width: 630px) {
  .MainDescription_wrapper__U-GDs .MainDescription_text__V9Nhg {
    padding: 23px 10px;
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MainDescription/MainDescription.module.scss"],"names":[],"mappings":"AACA;EACE,mBAAA;AAAF;AAIE;EACE,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,8BAAA;EACA,aAAA;EACA,uBAAA;AAFJ;AAME;EACE,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;EAEA,eAAA;EACA,gBAAA;EACA,iBAAA;AALJ;AAQI;EAGE,eAAA;EACA,gBAAA;EACA,iBAAA;AARN;AAsBA;EAEI;IACE,kBAAA;EArBJ;AACF;AA0BA;EAEI;IACE,kBAAA;IACA,eAAA;EAzBJ;AACF","sourcesContent":["\n.wrapper {\n  margin-bottom: 80px;\n  \n\n\n  .title {\n    align-items: center;\n    text-align: center;\n    width: 200px;\n    height: 40px;\n    background-color: #363636;\n    margin-left: auto;\n    margin-right: auto;\n    color: #fff;\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 24px;\n    border-radius: 8px 8px 0px 0px;\n    display: flex;\n    justify-content: center;\n  }\n  \n  \n  .text {\n    text-align: center;\n    border: 1px solid #363636;\n    padding: 25px 190px;\n    border-radius: 16px;\n\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 24px;\n\n\n    .decor {\n\n\n      font-size: 18px;\n      font-weight: 700;\n      line-height: 24px;\n  \n\n\n    }\n  }\n  \n  @media (max-width: 1360px) {\n  }\n  \n  @media (max-width: 768px) {\n  }\n}\n\n@media screen and (max-width: 1050px) {\n  .wrapper {\n    .text {\n      padding: 25px 50px;\n    }\n  } \n}\n\n\n@media screen and (max-width: 630px) { \n  .wrapper {\n    .text {\n      padding: 23px 10px;\n      font-size: 14px;\n    }\n  } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MainDescription_wrapper__U-GDs`,
	"title": `MainDescription_title__nuBPI`,
	"text": `MainDescription_text__V9Nhg`,
	"decor": `MainDescription_decor__AUtt5`
};
export default ___CSS_LOADER_EXPORT___;
