import React, { useEffect, useState } from "react";

import css from "./Footer.module.scss";
import Title from "../Title/Title";
import Container from "../Container";
import { useTranslation } from "react-i18next";
import Logo from "../Logo";
import Navigation from "../Navigation";

const Footer = ({ data }) => {
  const { t, i18n } = useTranslation();
  // console.log(data);

  return (
    <Container>
      <div className={css.wrapper}>
        <div className={css.logo}>
          {/* <Logo color = 'red'/> */}
          <Logo />
        </div>

        <div className={css.wrapperNavLine}>
          <Navigation list={data} showStyle="line" />
        </div>

        <p className={css.textTitle}>© 1974 — 2023 ТзОВ «Радокорм»</p>

        <div className={css.wrapperDecor}>
          <img src={"/media/images/decor/decor_1.png"} alt="decor_1" />
        </div>
      </div>
    </Container>
  );
};

export default Footer;
