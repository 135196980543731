import React, { useEffect } from "react";

import allData from "../../allData";

import { useTranslation } from "react-i18next";

import css from "./Compost.module.scss";
import MainTitle from "../../components/MainTitle/MainTitle";
import AboutUs from "../../components/AboutUs/AboutUs";
import Product from "../../components/Product";
import Description from "../../components/Description";
import Partners from "../../components/Partners/Partners";
import Contacts from "../../components/Contacts";
import Info from "../../components/Info";
import Footer from "../../components/Footer";
import MainDescription from "../../components/MainDescription/MainDescription";
import StorageConditions from "../../components/StorageСonditions";
import Packaging from "../../components/Packaging";
import { MainSlider } from "../../components/MainSlider/MainSlider";
import Container from "../../components/Container";
import DetailsCompost from "../../components/DetailsCompost";
import brownBg from '../../media/brown-bg.svg';
import brownBgSafari from '../../media/brown-bg-safari.svg';
import { useState } from "react";

const {

  dataPartners,
  dataContacts,
  dataInfo,
  dataMainDescriptionCompost,
  dataStorageConditions,
  dataPackagingCompost,
  slideCompostData,
  detailsCompost,
} = allData;

const Compost = () => {
  const [bg, setBg] = useState('');

  useEffect(()=>{
    const appleExpression = /(Mac|iPod|iPad)/i;
    if (appleExpression.test(navigator.platform)) {
      setBg(brownBgSafari);
    } else {
      setBg(brownBg);
    }
  }, [])

  return (
    <>
      <section className={css["head-compost"]} style={{backgroundImage: `url(${bg})`}}>
        <MainSlider slideData={'slideCompostData'} />
      </section>

      <section>
        <MainDescription data={'dataMainDescriptionCompost'} />
      </section>

      <section>
        <DetailsCompost data={'detailsCompost'} />
      </section>

      <section>
        <StorageConditions data={'dataStorageConditions'} />
      </section>
      <section>
        <Packaging data={'dataPackagingCompost'} type={"compost"} />
      </section>

      <section>
        <Partners data={'dataPartners'} />
      </section>
      <section>
       <Contacts data={'dataContacts'} />
      </section>
      <section>
        <Info data={'dataInfo'} />
      </section>
    </>
  );
};

export default Compost;
