// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LanguageSwitcher_wrapperLanguage__Z1ArS {
  display: flex;
  gap: 8px;
}
.LanguageSwitcher_wrapperLanguage__Z1ArS .LanguageSwitcher_langButton__WCO3- {
  position: relative;
  border: 1px solid #fff;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}
.LanguageSwitcher_wrapperLanguage__Z1ArS .LanguageSwitcher_activeLang__dlRi0:before {
  position: absolute;
  content: "";
  display: block;
  width: 10px;
  height: 5px;
  border-radius: 0px 0px 2px 2px;
  bottom: -5px;
  left: 35%;
  background-color: rgb(255, 255, 255);
}
@media screen and (max-width: 630px) {
  .LanguageSwitcher_wrapperLanguage__Z1ArS .LanguageSwitcher_langButton__WCO3- {
    width: 24px;
    height: 24px;
    font-size: 8px;
  }
  .LanguageSwitcher_wrapperLanguage__Z1ArS .LanguageSwitcher_langButton__WCO3-:before {
    left: 28%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LanguageSwitcher/LanguageSwitcher.module.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,QAAA;AAAF;AAGE;EACE,kBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,6BAAA;EACF,eAAA;EAIA,eAAA;EACA,gBAAA;EACA,iBAAA;AAJF;AAkBI;EACE,kBAAA;EACA,WAAA;EACA,cAAA;EACA,WAAA;EACA,WAAA;EACA,8BACA;EACN,YAAA;EACA,SAAA;EACM,oCAAA;AAjBN;AAkCA;EAEI;IACE,WAAA;IACA,YAAA;IACA,cAAA;EAjCJ;EAkCI;IACE,SAAA;EAhCN;AACF","sourcesContent":[".wrapperLanguage {\n\n  display: flex;\n  gap: 8px;\n\n\n  .langButton {\n    position: relative;\n    border: 1px solid #fff;\n    width: 32px;\n    height: 32px;\n    border-radius: 8px;\n    color: #fff;\n    background-color: transparent;\n  cursor: pointer;\n  \n  \n\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 24px;\n\n  \n  \n  \n  \n\n\n\n\n  }\n\n\n\n    .activeLang:before {\n      position: absolute;\n      content: \"\"; \n      display: block;\n      width: 10px; \n      height: 5px; \n      border-radius: \n      0px 0px 2px 2px;\nbottom: -5px;\nleft: 35%;\n      background-color: rgb(255, 255, 255); \n     \n    }\n    \n\n\n\n\n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n  }\n}\n\n@media screen and (max-width: 630px) { \n  .wrapperLanguage {\n    .langButton {\n      width: 24px;\n      height: 24px;\n      font-size: 8px;\n      &:before { \n        left: 28%;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperLanguage": `LanguageSwitcher_wrapperLanguage__Z1ArS`,
	"langButton": `LanguageSwitcher_langButton__WCO3-`,
	"activeLang": `LanguageSwitcher_activeLang__dlRi0`
};
export default ___CSS_LOADER_EXPORT___;
