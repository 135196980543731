// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_wrapper__RAsdB {
  display: flex;
  justify-content: space-between;
  border-radius: 14px;
  overflow: hidden;
  background-color: #363636;
  padding-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
}
.Footer_wrapper__RAsdB .Footer_logo__w79hm {
  margin-bottom: 32px;
}
.Footer_wrapper__RAsdB .Footer_wrapperText__Idazh {
  display: flex;
  gap: 15px;
  margin-bottom: 32px;
}
.Footer_wrapper__RAsdB .Footer_wrapperText__Idazh {
  margin-bottom: 32px;
}
.Footer_wrapper__RAsdB .Footer_textTitle__\\+oKqC {
  margin-bottom: 32px;
  color: #636363;
  margin-top: 30px;
}
.Footer_wrapper__RAsdB .Footer_button__u1aIr {
  width: 96px;
  height: 64px;
  background-color: transparent;
  border-radius: 16px;
  border: 1px solid #636363;
  border-radius: 16px;
  color: var(--main-color);
}
.Footer_wrapper__RAsdB .Footer_wrapperDecor__MpK0c {
  height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EAEA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;EACA,iBAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AAAA;AAEA;EACE,mBAAA;AAAF;AAEA;EACE,aAAA;EACA,SAAA;EAEA,mBAAA;AADF;AAIA;EACE,mBAAA;AAFF;AAKA;EACE,mBAAA;EACA,cAAA;EACA,gBAAA;AAHF;AAOA;EACE,WAAA;EACF,YAAA;EACA,6BAAA;EAEA,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,wBAAA;AANA;AAUA;EACE,YAAA;AARF","sourcesContent":[".wrapper {\n// border: 1px solid tomato;\ndisplay: flex;\njustify-content: space-between;\nborder-radius: 14px;\noverflow: hidden;\nbackground-color: #363636;\npadding-top: 30px;\nflex-direction: column;\njustify-content: center;\nalign-items: center;\nmargin-bottom: 70px;\n\n.logo {\n  margin-bottom: 32px;\n}\n.wrapperText { \n  display: flex;\n  gap: 15px;\n  \n  margin-bottom: 32px;\n}\n\n.wrapperText {\n  margin-bottom: 32px;\n}\n\n.textTitle {\n  margin-bottom: 32px;\n  color: #636363;\n  margin-top: 30px;\n}\n\n\n.button {\n  width: 96px;\nheight: 64px;\nbackground-color: transparent;\n\nborder-radius: 16px;\nborder: 1px solid #636363;\nborder-radius: 16px;\ncolor: var(--main-color);\n\n}\n\n.wrapperDecor {\n  height: 80px;\n}\n\n \n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Footer_wrapper__RAsdB`,
	"logo": `Footer_logo__w79hm`,
	"wrapperText": `Footer_wrapperText__Idazh`,
	"textTitle": `Footer_textTitle__+oKqC`,
	"button": `Footer_button__u1aIr`,
	"wrapperDecor": `Footer_wrapperDecor__MpK0c`
};
export default ___CSS_LOADER_EXPORT___;
