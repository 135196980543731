// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UniversalModalWindow_modal__b7UFe {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  transition: 0.5s;
}
.UniversalModalWindow_modal__b7UFe.UniversalModalWindow_active__kAKhD {
  opacity: 1;
  pointer-events: all;
}

.UniversalModalWindow_modal__content__VFkVj {
  border-radius: 15px;
  background-color: #fff;
  width: 70vw;
  transform: scale(0.5);
  transition: 0.4s all;
}
.UniversalModalWindow_modal__content__VFkVj.UniversalModalWindow_active__kAKhD {
  transform: scale(1);
}`, "",{"version":3,"sources":["webpack://./src/components/UniversalModalWindow/UniversalModalWindow.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,oCAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;EACA,oBAAA;EACA,gBAAA;EACA,gBAAA;AACF;AACE;EACE,UAAA;EACA,mBAAA;AACJ;;AAGA;EAEE,mBAAA;EACA,sBAAA;EAEA,WAAA;EAMA,qBAAA;EACA,oBAAA;AAPF;AASE;EACE,mBAAA;AAPJ","sourcesContent":[".modal {\n  height: 100vh;\n  width: 100vw;\n  background-color: rgba(0, 0, 0, 0.4);\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 3;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  opacity: 0;\n  pointer-events: none;\n  transition: 0.5s;\n  transition: 0.5s;\n\n  &.active {\n    opacity: 1;\n    pointer-events: all;\n  }\n}\n\n.modal__content {\n  // padding: 20px;\n  border-radius: 15px;\n  background-color: #fff;\n\n  width : 70vw;\n  // width : 100%;\n\n  // height: 200px;\n  // width: 200px;\n\n  transform: scale(0.5);\n  transition: 0.4s all;\n\n  &.active {\n    transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `UniversalModalWindow_modal__b7UFe`,
	"active": `UniversalModalWindow_active__kAKhD`,
	"modal__content": `UniversalModalWindow_modal__content__VFkVj`
};
export default ___CSS_LOADER_EXPORT___;
