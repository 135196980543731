import React, { useState, useEffect } from "react";
import Logo from "../Logo";
import css from "./Header.module.scss";
import Navigation from "../Navigation";
import LanguageSwitcher from "../LanguageSwitcher";
import Container from "../Container";
import { NavLink, useLocation } from "react-router-dom";

const Header = ({ data }) => {
  const [showBurger, setShowBurger] = useState(false);
  const location = useLocation();

  const toggleBurger = () => {
    setShowBurger(!showBurger);
  };

  // add green bg on buttons home page
  let buttonBg = "";
  if (location.pathname === "/") {
    buttonBg = " greenLine";
  }

  // useEffect(() => {
  //   console.log(showBurger);
  // }, [showBurger]);

  return (
    <Container>
      <header className={css.wrapper}>
        <div className={css.wrapperLogo}>
          <NavLink to={"/"}>
            <Logo color={"green"} />
          </NavLink>
        </div>

        <div className={css.wrapperButton}>
          <div className={css.wrapperNavLine + buttonBg}>
            <Navigation list={data} showStyle="line" />
          </div>

          <div className={css.wrapperSwitcher}>
            <LanguageSwitcher />
          </div>
        </div>
      </header>
    </Container>
  );
};

export default Header;
