import React from "react";
import css from "./GoogleMaps.module.scss";

const GoogleMaps = ({ path }) => {
  return (
    <div className={css.mapWrapper}>
      <iframe
        src={path}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMaps;
